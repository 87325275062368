<template>
<v-container fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0 justify-space-between">
            <h1>Умови доставки</h1>
        </v-card-title>
        <v-container fluid class="pa-0">
            <v-row no-gutters>
                <v-col sm="12" md="6" class="px-0 mb-8 pr-md-2">
                    <v-card-subtitle class="pa-0 my-2 mb-4">
                        Мінімальне замовлення: <span class="title second--text">500 грн.</span> для будь якого регіону клієнта.
                    </v-card-subtitle>
                    <v-card class="mb-4">
                        <v-card-title class="grey lighten-2 mb-4">Доставка в регіони</v-card-title>
                        <v-card-text>
                            Усі відправки <b>в регіони</b> - здійснюються перевізником: <b>Нова Пошта</b>.
                        </v-card-text>
                        <v-card-subtitle class="font-weight-bold second--text">Отримання негабаритних вантажів:</v-card-subtitle>
                        <v-card-text>
                            <div><b>До 5000 грн.</b> – відправка вантажу сплачується <b>ОТРИМУВАЧЕМ (!)</b>.</div>
                            <div><b>Понад 5000 грн.</b> – відправка вантажу сплачується <b>ВІДПРАВНИКОМ</b>.</div>
                        </v-card-text>
                        <v-card-subtitle class="font-weight-bold second--text">Отримання габаритних* вантажів</v-card-subtitle>
                        <v-card-text>
                            <p>Відправка вантажу сплачується <b>ОТРИМУВАЧЕМ (!)</b> за будь-якої суми відвантаження.</p>
                            <p class="font-italic">* <b>Габаритним</b> - вважається вантаж, що містить товар з груп, незалежно від кількості:<br>
                                - <b>ПОСУД</b>, включно з кухонним приладдям<br>
                                - <b>ВЕЛИКА ПОБУТОВА ТЕХНІКА</b>, включно з вбудованою та кліматичною<br>
                                - <b>МАЛА ПОБУТОВА ТЕХНІКА</b>, окремі групи товару:
                                <ul>
                                    <li>Електросушарки для продуктів</li>
                                    <li>Мікрохвильові печі</li>
                                    <li>Мультиварки</li>
                                    <li>Очищувачі повітря</li>
                                    <li>Пилососи</li>
                                    <li>Парові системи</li>
                                    <li>Відпарювачі</li>
                                    <li>Фритюрниці</li>
                                    <li>Хлібопічки</li>
                                    <li>Електропіч</li>
                                </ul>
                            </p>
                        </v-card-text>
                    </v-card>
                    <v-card>
                        <v-card-title class="grey lighten-2 mb-4">Доставка по Києву **</v-card-title>
                        <v-card-text>
                            **доставка по Києву включає також доставку в найближчі населені пункти:
                        </v-card-text>
                        <div class="px-4">
                            <v-chip label outlined pill class="mr-4 mb-2">Білогородка</v-chip>
                            <!-- <v-chip label outlined pill class="mr-4 mb-2">Бориспіль (по Пт)</v-chip> -->
                            <v-chip label outlined pill class="mr-4 mb-2">Боярка</v-chip>
                            <v-chip label outlined pill class="mr-4 mb-2">Бровари (по Пн)</v-chip>
                            <v-chip label outlined pill class="mr-4 mb-2">Буча (по Пн)</v-chip>
                            <v-chip label outlined pill class="mr-4 mb-2">Вишгород</v-chip>
                            <v-chip label outlined pill class="mr-4 mb-2">Вишневе</v-chip>
                            <!-- <v-chip label outlined pill class="mr-4 mb-2">Гатне</v-chip> -->
                            <v-chip label outlined pill class="mr-4 mb-2">Гостомель (по Пн)</v-chip>
                            <v-chip label outlined pill class="mr-4 mb-2">Ірпінь (по Пн)</v-chip>
                            <v-chip label outlined pill class="mr-4 mb-2">Петропавлівська Борщагівка</v-chip>
                            <!-- <v-chip label outlined pill class="mr-4 mb-2">Проліски</v-chip> -->
                            <v-chip label outlined pill class="mr-4 mb-2">Свято-Петрівське</v-chip>
                            <v-chip label outlined pill class="mr-4 mb-2">Софіївська Борщагівка</v-chip>
                        </div>
                        <v-card-subtitle class="font-weight-bold second--text">Отримання БУДЬ-ЯКИХ товарів можливе:</v-card-subtitle>
                        <v-card-text>
                            <div class="pb-3">
                                <v-icon class="green--text">mdi-check</v-icon> Транспортом компанії, при замовленні <b>від 5000 грн</b>.
                            </div>
                            <div class="pb-3">
                                <v-icon class="green--text">mdi-check</v-icon> Самовивозом зі складів компанії, при замовленні <b>від 500 грн</b>.
                            </div>
                            <div class="pb-3">
                                <v-icon class="green--text">mdi-check</v-icon> На відділення Нової Пошти, згідно з нормами вище
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col sm="12" md="6">
                    <v-card :class="($vuetify.breakpoint.mdAndUp ? `px-4 ` : `pa-0` )" elevation="0">
                        <v-card-title :class="`pt-2 mb-4 primary--text ` + ($vuetify.breakpoint.mdAndUp ? `px-4 ` : `px-0` )">
                            Важливо!
                        </v-card-title>
                        <v-card-text :class="($vuetify.breakpoint.mdAndUp ? `px-4 ` : `px-0` )">
                            <!-- <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> В разі <b>СПЛАТИ КАРТКОЮ</b> при отриманні товару на <b>Новій Пошті</b> - комісія <b>3%</b> буде ретрансльована на Ваш баланс.
                            </div> -->
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Якщо отримувач <b>е з'явився</b> на відділення, або оформив <b>відмову</b> без належних причин та документування - товар буде відправлений у зворотньому напрямку, а вартість транспортних послуг за обидва напрямки буде ретрансльована на баланс отримувача.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Після підтвердження замовлення - <b>зміна умов оплати не можлива</b>.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Відправки <b>до 10 одиниць товарів</b> - мають бути прийняті за кількістю та якістю на відділенні Нової Пошти, або у присутності представника компанії, в момент отримання.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Відправки з кількістю <b>онад 10 одиниць товарів</b> - можуть бути прийняті за кількістю місць, з подальшим оглядом та прийманням за якістю та кількістю за межами відділення, або без присутності представника компанії. Претензії щодо таких відправок приймаються в триденний період після отримання. Рекомендуємо проводити відеофіксацію розпаковки таких відправок - це прискорить розгляд претензій в разі виявлення некондиції.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> <b>Ціна</b> замовлення – <b>дійсна</b> лише для відвантаження <b>в день замовлення</b>, та може бути змінена компанією в наступний день в односторонньому порядку.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> В разі <b>сплати кур’єру</b> – може бути сплачена лише <b>сума</b>, що була <b>обумовлена на етапі підтвердження</b> замовлення. <b>Зміна суми</b> в процесі доставки <b>не можлива</b>, і товар не буде відвантажено в разі несплати обумовленої суми.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Кур’єри не забирають повернень готового, некондиційного, чи будь-якого іншого товару, якщо <b>накладна на повернення</b> не була погоджена з менеджером та <b>підготовлена заздалегідь</b>. В разі повернення товару по накладній – клієнт несе відповідальність за збереження екземпляру накладної на повернення з підписом та ПІБ особи, що прийняла повернення.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Кур’єри <b>не очікують клієнта</b> більше ніж 10 хвилин для початку відвантаження, та більше ніж 10 хвилин – в процесі приймання товару.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Кур’єри <b>відвантажують</b> товар лише <b>«з борту»</b> авто. Занос товару на поверх / у магазин / вглиб ринку / та інші варіанти, що потребують залишити авто без нагляду – не можливі.
                            </div>
                            <div class="pb-4">
                                <v-icon class="green--text">mdi-check</v-icon> Перелічені правила – не є вичерпними, та можуть змінюватися і доповнюватися. Будь які незрозумілі питання щодо взаємодії – рекомендуємо проясняти заздалегідь з менеджером, що оброблює замовлення, або направляти за контактами нижче. Спілкування через поштову скриньку – має вищий пріоритет.
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</v-container>
</template>

<script>
import {
    mapActions,
} from 'vuex'

export default {
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    },
    data: () => ({}),
    methods: {
        ...mapActions(['getContactInfo']),
    },
    created() {
        this.getContactInfo()
            .then(data => {
                if (data.is24_7User && !data.isSellToEmployee) {
                    this.$vuetify.goTo(0)
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    },
}
</script>
